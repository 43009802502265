<script setup lang="ts">
const route = useRoute();
const storeHeader = useHeader();
</script>

<template>
  <Header
    :menu="storeHeader.burger"
    :back="storeHeader.back"
    :close="storeHeader.close"
    @back="storeHeader.onBack"
    @close="storeHeader.onClose"
    logo="scan"
    v-if="
      route.meta.hasOwnProperty('header')
        ? (route.meta.header as boolean)
        : true
    "
  />

  <main
    class="flex flex-grow flex-col"
    :class="{
      'with-bg-1': route.meta?.background === 1,
      'with-bg-2': route.meta?.background === 2,
      'with-bg-3': route.meta?.background === 3,
    }"
  >
    <RouterView v-slot="{ Component, route }">
      <template v-if="Component">
        <Suspense timeout="200">
          <component :is="Component" :key="route.fullPath" />

          <template #fallback>
            <div class="flex flex-grow items-center justify-center">
              <svg
                class="h-16 w-16 animate-spin text-red-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </template>
        </Suspense>
      </template>
    </RouterView>

    <slot />
  </main>
</template>

<style lang="css" scoped>
.with-bg-1 {
  @apply bg-right-bottom bg-no-repeat;
  background-image: url(/images/backgrounds/background-1.svg);
}
.with-bg-2 {
  background-image: url(/images/backgrounds/background-2.svg);
  background-repeat: no-repeat;
  background-position: bottom left;
}
.with-bg-3 {
  background-image: url(/images/backgrounds/background-3.svg);
  background-repeat: no-repeat;
  background-position: bottom left;
}
</style>
